* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

input,
button,
textarea,
select {
	margin: 0;
	font: inherit;
}
ul{
	list-style: none;
}
a {
	text-decoration: none;
}
img {
	object-fit: cover;
}
