:root {
	--line-height-m: 20px;

	// FONTS
	--font-size-m: 14px;
	--font-bold: 700;
	font-family: "Sora", sans-serif;

	// COLORS
	--color-white: #fff;
	--color-gray: #505050;
	// /COLORS
	--first-color: hsl(231, 44%, 56%);
	--first-color-alt: hsl(231, 44%, 51%);
}
.rdrSelected {
	background: #8562ce;
}
.rdrDayNumber {
	& > span {
		&::after {
			background: #8562ce !important;
		}
	}
}

@keyframes button-loading-spinner {
	from {
		transform: rotate(0turn);
	}

	to {
		transform: rotate(1turn);
	}
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
@keyframes load {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-1.75rem);
	}
}

input[type="number"] {
	-moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	/* display: none; <- Crashes Chrome on hover */
	-webkit-appearance: none;
	margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
